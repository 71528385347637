<template>
  <section class="contact-form u-stack">
    <h2 class="contact-form__heading">Solicitar una reunión</h2>
    <div class="contact-form__content u-stack--sm">
      <div class="contact-form__form">
        <div v-if="isFormSent" class="contact-form__form-sent u-stack--sm">
          <p>Solicitud de reunión enviada con éxito!</p>
          <Button type="button" @click="backToForm">Enviar nueva solicitud</Button>
        </div>
        <form
          v-else
          class="u-stack--sm"
          @submit.prevent="onSubmitForm"
        >
          <div class="grid">
            <div>
              <Input
                label="Nombre"
                :required="true"
                id="name"
                v-model="form.name"
                :error="formError.name"
              ></Input>
            </div>
            <div>
              <Input
                label="Correo electrónico"
                :required="true"
                id="email"
                type="email"
                v-model="form.email"
                :error="formError.email"
              ></Input>
            </div>
          </div>
          <Input label="Empresa" id="company" v-model="form.company"></Input>
          <Input label="Ubicación" id="location" v-model="form.location"></Input>
          <Textarea label="Mensaje" id="message" v-model="form.message"></Textarea>

          <div class="grid grid--align-center">
            <p class="contact-form__required-info">Campos obligatorios</p>
            <div class="grid__auto">
              <Button type="submit" variant="primary">Enviar</Button>
            </div>
          </div>
        </form>
      </div>
      <div class="u-stack--sm">
        <ul>
          <li>Avenida de Nazaret 3. Local derecho.</li>
          <li>28009 Madrid</li>
        </ul>

        <ul>
          <li>0034 914174840</li>
          <li>Lunes a viernes, de 08h30 a 18h30</li>
        </ul>

        <div>
          Correo electronico: <a href="mailto: madrid@geostar.es">madrid@geostar.es</a>
        </div>

      </div>
    </div>
  </section>
</template>
<script setup>
import { reactive, ref } from 'vue'

import { useNotificationsStore } from '@stores/notifications.js'

const notificationsStore = useNotificationsStore()

const form = reactive({
  name: '',
  email: '',
  company: '',
  location: '',
  message: '',
})
const formError = reactive({
  name: '',
  email: '',
})

const isFormSent = ref(false)

const validateForm = () => {

  let isValid = true

  // reset errors
  formError.name = ''
  formError.email = ''

  if (!form.name) {
    isValid = false
    formError.name = 'Introduzca su nombre'
  }

  if (form.email) {
    const isEmailValid = form.email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    if (!isEmailValid) {
      isValid = false
      formError.email = 'Introduzca una dirección de correo electrónico válida'
    }
  } else {
    isValid = false
    formError.email = 'Insertar correo electrónico'
  }

  return isValid
}
const onSubmitForm = () => {
  const isValid = validateForm()

  if (isValid) {
    const formRequest = new XMLHttpRequest()
    const url = ''
    const params = `
      name=${form.name}&
      email=${form.email}&
      company=${form.company}&
      location=${form.location}&
      message=${form.message}
    `
    formRequest.open('POST', url, true)
    formRequest.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
    formRequest.onreadystatechange = () => {
      if (formRequest.readyState === 4) {
        if (formRequest.status === 200) {
          // success
          notificationsStore.add({
            content: 'Solicitud de reunión enviada correctamente'
          })
          isFormSent.value = true
        } else {
          // error
          notificationsStore.add({
            content: 'No se ha podido solicitar la reunión. Vuelva a intentarlo',
            type: 'error'
          })
        }
      }
    }
    formRequest.send(params)
  }
}

const backToForm = () => {
  isFormSent.value = false
}
</script>
<style scoped>
.contact-form {
  container-type: inline-size;
  padding: var(--gap);
  background-color: var(--color-main-500);
  color: rgba(255, 255, 255, .8);
}
.contact-form__heading {
  text-align: center;
  letter-spacing: .5rem;
  font-size: 3rem;
  font-weight: 500;
}
.contact-form__content {
  display: flex;
  gap: var(--gap);
  max-width: 80rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.contact-form__form {
  flex-grow: 1.5;
}
.contact-form__form-sent {
  padding: var(--gap) var(--gap-sm);
  border: 1px solid var(--color-success-500);
  background-color: var(--color-success-200);
  text-align: center;
}
.contact-form__form-sent p {
  font-size: 1.8rem;
  font-weight: bold;
}
.contact-form__required-info {
  color: var(--color-neutral-500);
}
.contact-form__required-info::before {
  content: "* ";
  color: var(--color-danger-500);
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 0;
}
@container (width < 799px) {
  .contact-form__content {
    display: block;
  }
}
</style>