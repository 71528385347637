import { default as contactsJuLeaU9dNPMeta } from "/usr/local/var/www/ccs/clients/wamos/geotur.es/pages/contacts.vue?macro=true";
import { default as index1o0XPX1awoMeta } from "/usr/local/var/www/ccs/clients/wamos/geotur.es/pages/index.vue?macro=true";
import { default as billingDZoVQTT2M8Meta } from "/usr/local/var/www/ccs/clients/wamos/geotur.es/pages/product/billing.vue?macro=true";
import { default as bookingsdmEyFww6Y2Meta } from "/usr/local/var/www/ccs/clients/wamos/geotur.es/pages/product/bookings.vue?macro=true";
import { default as integrationsxxtgveBLybMeta } from "/usr/local/var/www/ccs/clients/wamos/geotur.es/pages/product/integrations.vue?macro=true";
import { default as reportY1LQeZiZ0MMeta } from "/usr/local/var/www/ccs/clients/wamos/geotur.es/pages/product/report.vue?macro=true";
import { default as corporateqJ3UV4KI5DMeta } from "/usr/local/var/www/ccs/clients/wamos/geotur.es/pages/services/corporate.vue?macro=true";
import { default as groupsR5eoSEFw3wMeta } from "/usr/local/var/www/ccs/clients/wamos/geotur.es/pages/services/groups.vue?macro=true";
import { default as meetingsWtVL9isF9oMeta } from "/usr/local/var/www/ccs/clients/wamos/geotur.es/pages/services/meetings.vue?macro=true";
export default [
  {
    name: contactsJuLeaU9dNPMeta?.name ?? "contacts",
    path: contactsJuLeaU9dNPMeta?.path ?? "/contacts",
    meta: contactsJuLeaU9dNPMeta || {},
    alias: contactsJuLeaU9dNPMeta?.alias || [],
    redirect: contactsJuLeaU9dNPMeta?.redirect || undefined,
    component: () => import("/usr/local/var/www/ccs/clients/wamos/geotur.es/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: index1o0XPX1awoMeta?.name ?? "index",
    path: index1o0XPX1awoMeta?.path ?? "/",
    meta: index1o0XPX1awoMeta || {},
    alias: index1o0XPX1awoMeta?.alias || [],
    redirect: index1o0XPX1awoMeta?.redirect || undefined,
    component: () => import("/usr/local/var/www/ccs/clients/wamos/geotur.es/pages/index.vue").then(m => m.default || m)
  },
  {
    name: billingDZoVQTT2M8Meta?.name ?? "product-billing",
    path: billingDZoVQTT2M8Meta?.path ?? "/product/billing",
    meta: billingDZoVQTT2M8Meta || {},
    alias: billingDZoVQTT2M8Meta?.alias || [],
    redirect: billingDZoVQTT2M8Meta?.redirect || undefined,
    component: () => import("/usr/local/var/www/ccs/clients/wamos/geotur.es/pages/product/billing.vue").then(m => m.default || m)
  },
  {
    name: bookingsdmEyFww6Y2Meta?.name ?? "product-bookings",
    path: bookingsdmEyFww6Y2Meta?.path ?? "/product/bookings",
    meta: bookingsdmEyFww6Y2Meta || {},
    alias: bookingsdmEyFww6Y2Meta?.alias || [],
    redirect: bookingsdmEyFww6Y2Meta?.redirect || undefined,
    component: () => import("/usr/local/var/www/ccs/clients/wamos/geotur.es/pages/product/bookings.vue").then(m => m.default || m)
  },
  {
    name: integrationsxxtgveBLybMeta?.name ?? "product-integrations",
    path: integrationsxxtgveBLybMeta?.path ?? "/product/integrations",
    meta: integrationsxxtgveBLybMeta || {},
    alias: integrationsxxtgveBLybMeta?.alias || [],
    redirect: integrationsxxtgveBLybMeta?.redirect || undefined,
    component: () => import("/usr/local/var/www/ccs/clients/wamos/geotur.es/pages/product/integrations.vue").then(m => m.default || m)
  },
  {
    name: reportY1LQeZiZ0MMeta?.name ?? "product-report",
    path: reportY1LQeZiZ0MMeta?.path ?? "/product/report",
    meta: reportY1LQeZiZ0MMeta || {},
    alias: reportY1LQeZiZ0MMeta?.alias || [],
    redirect: reportY1LQeZiZ0MMeta?.redirect || undefined,
    component: () => import("/usr/local/var/www/ccs/clients/wamos/geotur.es/pages/product/report.vue").then(m => m.default || m)
  },
  {
    name: corporateqJ3UV4KI5DMeta?.name ?? "services-corporate",
    path: corporateqJ3UV4KI5DMeta?.path ?? "/services/corporate",
    meta: corporateqJ3UV4KI5DMeta || {},
    alias: corporateqJ3UV4KI5DMeta?.alias || [],
    redirect: corporateqJ3UV4KI5DMeta?.redirect || undefined,
    component: () => import("/usr/local/var/www/ccs/clients/wamos/geotur.es/pages/services/corporate.vue").then(m => m.default || m)
  },
  {
    name: groupsR5eoSEFw3wMeta?.name ?? "services-groups",
    path: groupsR5eoSEFw3wMeta?.path ?? "/services/groups",
    meta: groupsR5eoSEFw3wMeta || {},
    alias: groupsR5eoSEFw3wMeta?.alias || [],
    redirect: groupsR5eoSEFw3wMeta?.redirect || undefined,
    component: () => import("/usr/local/var/www/ccs/clients/wamos/geotur.es/pages/services/groups.vue").then(m => m.default || m)
  },
  {
    name: meetingsWtVL9isF9oMeta?.name ?? "services-meetings",
    path: meetingsWtVL9isF9oMeta?.path ?? "/services/meetings",
    meta: meetingsWtVL9isF9oMeta || {},
    alias: meetingsWtVL9isF9oMeta?.alias || [],
    redirect: meetingsWtVL9isF9oMeta?.redirect || undefined,
    component: () => import("/usr/local/var/www/ccs/clients/wamos/geotur.es/pages/services/meetings.vue").then(m => m.default || m)
  }
]